import React from 'react'

const HeaderHinnasto = (props) => (
    <header id="header">
        <h1>Hyvinvointistudio Hetki</h1>
        <p>Hinnasto</p>
    </header>
)

export default HeaderHinnasto
