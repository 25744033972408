import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderHinnasto from '../components/HeaderHinnasto'
import {Link} from "gatsby";

class Hinnasto extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet title="Hinnasto - Hyvinvointistudio Hetki"/>
                <HeaderHinnasto/>
                <div id="main">
                    <section id="content" className="main">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <h2 id="jooga">Joogahetki</h2>
                                        <sup>Perustunti 60-75min</sup>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Kertamaksu</td>
                                    <td align="right">15€</td>
                                </tr>
                                <tr>
                                    <td>5x</td>
                                    <td align="right">70€</td>
                                </tr>
                                <tr>
                                    <td>10x</td>
                                    <td align="right">130€</td>
                                </tr>
                                <tr>
                                    <td>Restoratiivinen jooga 90 min kertamaksu</td>
                                    <td align="right">20€</td>
                                </tr>
                                <tr>
                                    <td><p></p></td>
                                </tr>
                                <tr>
                                    <td><sup>Hinnat sis. ALV 10%</sup></td>
                                </tr>
                                <tr>
                                    <td><p></p></td>
                                </tr>
                                <tr>
                                    <td>Yksityis- ja tilaustunnit</td>
                                    <td align="right">Kysy hinta</td>
                                </tr>


                                <tr>
                                    <td colSpan="2"><hr/></td>
                                </tr>

                                <tr>
                                    <td colSpan="2"><h2 id="oma">Omahetki</h2></td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Lihaspohjainen koko kehon hieronta</b><br/><small>(Tuoksuton tai eteerinen
                                        öljy)</small>
                                    </td>
                                </tr>
                                <tr>
                                    <td>60 min</td>
                                    <td align="right">55€</td>
                                </tr>
                                <tr>
                                    <td>90 min</td>
                                    <td align="right">75€</td>
                                </tr>
                                <tr>
                                    <td><p></p></td>
                                </tr>
                                <tr>
                                    <td><b>Intialainen päähieronta</b></td>
                                </tr>
                                <tr>
                                    <td>30 min</td>
                                    <td align="right">35€</td>
                                </tr>
                                <tr>
                                    <td>60 min (sis. myös käsihieronnan)</td>
                                    <td align="right">50€</td>
                                </tr>
                                <tr>
                                    <td><p></p></td>
                                </tr>
                                <tr>
                                    <td>Tiibetiläinen äänimaljahoito 60 min</td>
                                    <td align="right">60€</td>
                                </tr>
                                <tr>
                                    <td>Yksilöllinen rentoutusohjaus 60 min</td>
                                    <td align="right">40€</td>
                                </tr>
                                <tr>
                                    <td><p></p></td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <b>Hetki sinulle</b><br/>
                                        Saat juuri sinulle suunnitellun palvelukokonaisuuden. Voit valita
                                        hierontaa, rentoutumista, joogaa ja
                                        kehonhuoltoa.<br />
                                        Mitä sinä kaipaat juuri nyt?
                                    </td>
                                </tr>
                                <tr>
                                    <td>90 min</td>
                                    <td align="right">80€</td>
                                </tr>
                                <tr>
                                    <td><p></p></td>
                                </tr>
                                <tr>
                                    <td><sup>Hinnat sis. ALV 24%</sup></td>
                                </tr>


                                <tr>
                                    <td colSpan="2">
                                        <hr/>
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan="2">
                                        <h2 id="rento" style={{ marginBottom: '0em' }}>Rentohetki</h2>
                                        <small>(Pienryhmät, max 6 hlöä)</small>
                                    </td>
                                </tr>
                                <tr>
                                    <td><p></p></td>
                                </tr>
                                <tr>
                                    <td>Ohjattu rentoutus 45-60 min</td>
                                    <td align="right">12€</td>
                                </tr>
                                <tr>
                                    <td>Restoratiivinen jooga 90 min</td>
                                    <td align="right">20€</td>
                                </tr>
                                <tr>
                                    <td>Sointukylpyrentoutus 60 min</td>
                                    <td align="right">15€</td>
                                </tr>
                                <tr>
                                    <td>Yksilöllinen rentoutusohjaus 60 min</td>
                                    <td align="right">40€</td>
                                </tr>
                                <tr>
                                    <td><p></p></td>
                                </tr>
                                <tr>
                                    <td><sup>Hinnat sis. ALV 24%</sup></td>
                                </tr>

                                <tr>
                                    <td colSpan="2">
                                        <hr/>
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan="2"><h2 id="treeni">Treenihetki</h2></td>
                                </tr>
                                <tr>
                                    <td>
                                        Treenitunteja järjestetään toiveiden mukaan (esim. kehonpaino, HIIT, kahvakuula)
                                    </td>
                                    <td align="right">Hinta erikseen</td>
                                </tr>
                                <tr>
                                    <td><p></p></td>
                                </tr>
                                <tr>
                                    <td colSpan="2">Personal Trainer-palvelut</td>
                                </tr>
                                <tr>
                                    <td><ul><li>saliohjelma tai kotitreeniohjeet</li></ul></td>
                                    <td align="right">40€</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">Ravinto-ohjaus</td>
                                </tr>
                                <tr>
                                    <td>
                                        <ul>
                                            <li>ruokapäiväkirja + analyysi</li>
                                        </ul>
                                    </td>
                                    <td align="right">60€</td>
                                </tr>
                                <tr>
                                    <td colSpan="2">PT paketti esim. 3 tai 6kk</td>
                                </tr>
                                <tr>
                                    <td>
                                        <ul>
                                            <li>lähtötasotestit</li>
                                            <li>ruokapäiväkirja, analyysi + ohjeet</li>
                                            <li>kuntosali- tai kotitreeniohjelma</li>
                                            <li>tapaamiset sovitusti</li>
                                            <li>kuntotesti lopussa</li>
                                        </ul>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        Hinta muodostuu tapaamiskertojen mukaan
                                    </td>
                                    <td align="right">Kysy hinta</td>
                                </tr>
                                <tr>
                                    <td><p></p></td>
                                </tr>
                                <tr>
                                    <td><sup>Hinnat sis. ALV 24%</sup></td>
                                </tr>

                            </tbody>
                        </table>

                        <footer className="major">
                            <ul className="actions">
                                <li>
                                    <Link to="/" className="button special">
                                        Takaisin etusivulle
                                    </Link>
                                </li>
                            </ul>
                        </footer>

                    </section>

                </div>
            </Layout>
        )
    }
}

export default Hinnasto
